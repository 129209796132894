/* banner.css */
.banner {
    padding-top: 80px;
    background-color: #4A3B8D11;
}

.banner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Set to space-between for left and right alignment */
    align-items: center;
    height: 80vh;
    min-height: 500px;
    max-width: 1200px;
    margin: auto;
    box-shadow: none;
}

.banner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50%;
}

.banner-text {
    width: 100%;
    /* Take up 100% of the available width */
}

.banner-text h1 {
    font-size: 3em;
    color: #DF725C;
    margin-top: 0;
    margin-bottom: 36px;
}

.banner-text p {
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 8px;
}

.store-badges {
    width: 100%;
    /* Take up 100% of the available width */
    display: flex;
    gap: 16px;
    margin-top: 56px;

}

.store-badges img {
    max-height: 50px;
    width: auto;
    border-radius: 12px;
    /* Maintain the original width of the images */
}

.banner-image {
    flex: 1;
    /* Take up remaining space */
    display: flex;
    height: 100%;
    justify-content: flex-end;
    /* Align to the right */
}

.banner-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

@media only screen and (max-width: 768px) {
    .banner-container {
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .banner-content {
        max-width: none;
    }

    .banner-image {
        margin-top: 0;
        /* Adjust spacing between text and image on smaller screens */
        justify-content: center;
        height: auto;
    }

    .store-badges {
        justify-content: center;
    }

    .banner-text h1 {
        font-size: 2em;
    }
}

@media screen and (max-width: 1200px) {
    .banner {
        padding: 80px 16px;
    }
}

/* Tablet Styles */
@media only screen and (max-width: 1024px) {
    .banner {
        text-align: center;
    }

    .banner-container {
        flex-direction: row;
        /* Change the order for tablets */
        align-items: center;
        justify-content: center;
        text-align: center;
        display: inline-block;
        /* Center horizontally for tablets */
    }

    .banner-content {
        width: 100%;
        max-width: 100%;
        /* Reset max-width for tablet layout */
    }

    .banner-image {
        margin-top: 48px;
        margin-right: 0;
        height: 70%;
    }

    .banner-description-group {
        display: inline-block;
        text-align: left;
    }

    .store-badges {
        justify-content: center;
    }

    .banner-text h1 {
        font-size: 2em;
        margin: 56px 0px;
    }
}