.support-page-container {
  width: 100%;
}

.support-page-content {
  max-width: 1200px;
  margin: auto;
  padding-top: 96px;
}

.delete-account-container {
  margin-bottom: 56px;
  display: block;
  width: 50%;
}

/* SupportSection.css */

.support-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.text-content {
  padding: 20px 0;
}

.delete-text-content {
  width: 100%;
}

.support-image {
  width: 60%;
  height: 40%;
  object-fit: contain;
  display: flex;

}

/* RequestDeletionSection.css */

.flat-input {
  width: 100%;
  padding: 16px 16px;
  border: 1px solid #ccc;
  /* Grey border color */
  border-radius: 12px;
  background-color: #f5f5f5;
  /* Grey color fill */
  box-sizing: border-box;
  margin-bottom: 10px;
  max-width: 100%;
  font-family: 'Poppins';
  font-size: 12pt;
}

.flat-input:focus {
  outline: none;
  border-color: #4a3b8d;
  /* Highlight color on focus */
}

.form {
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: flow;
  display: flow-root;
  width: 100%;
}

.deletion-hint {
  font-size: 12px;
  color: #444;
  padding-bottom: 24px;
}


/* Responsive layout for larger screens */
@media (min-width: 768px) {
  .support-section {
    flex-direction: row;
  }
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .support-image {
    width: 50%;
    height: auto;
  }

  .support-page-content {
    padding: 124px 16px;
  }

  .column {
    width: 100%;
    display: contents;
    padding: 0 16px;
  }

  .delete-account-container {
    width: 100%;
  }

  .support-section {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 1200px) {
  .column {
    padding: 0 16px;
  }

  .support-section {
    margin-bottom: 48px;
  }

}