/* sidebar.css */
.sidebar {
  position: fixed;
  top: 0;
  right: -100%; /* Initially hide the sidebar off-screen on the right */
  width: 100%; /* Set the width to 100% of the viewport */
  height: 100%;
  background-color: #f4f4f4; /* Set the background color */
  transition: right 0.3s ease-in-out; /* Define the transition properties for the right property */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  padding-top: 30px; /* Add padding to top to center align the content */
}

.sidebar-button {
  background-color: transparent;
  border: none;
  color: #000; /* Black color for text */
  font-size: 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500; /* Medium variant */
  text-decoration: none;
  padding: 12px 24px;
  margin-top: 8px;
  text-align: center;
  outline: none;
  border-radius: 12px; /* Match the border radius */
  transition: background-color 0.3s ease-in-out; /* Transition for the hover effect */
  -webkit-tap-highlight-color: transparent;
  /* For WebKit browsers (Chrome, Safari) */
  a {
    color: inherit; /* Inherit text color */
    text-decoration: none; /* Remove default underline */
  }
}


.sidebar-button:hover {
  background-color: rgba(223, 114, 92, 0.1); /* Match the hover effect color */
}

.close-icon {
  font-size: 20px;
  cursor: pointer;
  color: #4A3B8D;
  margin-bottom: 20px; /* Add margin to create space between the close icon and menu items */
}

.sidebar.open {
  right: 0; /* Slide into the viewport from the right */
}

@keyframes slideIn {
  from {
    right: -100%; /* Start off-screen to the right */
  }
  to {
    right: 0; /* Slide into the viewport from the right */
  }
}

.sidebar-download-button {
  background-color: #4A3B8D; /* Set button color */
  color: #fff; /* Set text color */
  margin-left: auto; /* Auto margin left to center horizontally */
  margin-right: auto; /* Auto margin right to center horizontally */
  margin-top: 4em; /* Adjust margin top as needed */
  margin-bottom: 2em; /* Adjust margin bottom as needed */
  padding: 12px 24px;
  border-radius: 12px; /* Match the border radius */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  transition: background-color 0.3s ease-in-out; /* Transition for the hover effect */
}

.sidebar-download-button:hover {
  background-color: #362c66; /* Darker version of the button color on hover */
}