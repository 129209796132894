a {
    -webkit-tap-highlight-color: transparent;
    /* For WebKit browsers (Chrome, Safari) */
}

body {
    font-family: 'Poppins', sans-serif;
}

.primary-button {
    text-decoration: none;
    padding: 16px 20px;
    border-radius: 16px;
    border: none;
    font-weight: 500;
    font-size: 0.9em;
    /* Increased border radius */
    -webkit-tap-highlight-color: transparent;
    background-color: #4A3B8D;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.primay-button:hover {
    background-color: #362c66;
    /* Darker version of the button color on hover */
}

.accent-button {
    text-decoration: none;
    padding: 16px 20px;
    border-radius: 16px;
    border: none;
    font-weight: 500;
    font-size: 0.9em;
    /* Increased border radius */
    -webkit-tap-highlight-color: transparent;
    background-color: #DF725C;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.accent-button:hover {
    background-color: #9E4D3D;
    /* Darker version of the button color on hover */
}

@media only screen and (max-width: 768px) {
    .page {
        display: inline-block;
    }
}