/* header.css */
.header {
    background-color: #fff;
    /* Add your preferred background color */
    border-bottom: 1px solid #e0e0e0;
    /* Material3 color scheme for the border */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: fixed;
    width: 100%;
    /* Full width of the viewport */
    z-index: 1000;
    /* Set z-index to ensure it appears above other elements */
}


.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* Ensure the container takes the full width */
}

.brand-name {
    display: flex;
    font-size: 36px;
    color: #4A3B8D;
    align-items: center;
    font-family: 'Teko', sans-serif;
    letter-spacing: 2px;
    /* Material Design 3 primary color for text */
    margin-right: auto;
    /* Move the logo to the left */
}

.brand-name img {
    display: flex;
    align-items: center;
    margin-right: 12px;
    width: 32px;
}

.menu {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
    margin-left: auto;
    /* Move the navbar to the right */
}

nav a.active {
    /* Add your active link styles here */
    background-color: rgba(223, 114, 92, 0.1);
}

.navbar-button {
    background-color: transparent;
    border: none;
    color: #000;
    /* Black color for text */
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
    /* Medium variant */
    text-decoration: none;
    padding: 16px 20px;
    border-radius: 12px;
    /* Increased border radius */
    -webkit-tap-highlight-color: transparent;
    /* For WebKit browsers (Chrome, Safari) */
}

.navbar-button:hover {
    background-color: rgba(223, 114, 92, 0.1);
    /* Button-like highlight on hover */
    text-decoration: none;
}

.download-button {
    background-color: #4A3B8D;
    color: #fff;
    margin-left: 2em;
    font-weight: 500;
}

.download-button:hover {
    background-color: #362c66;
    /* Darker version of the button color on hover */
}

.hamburger-icon {
    display: none;
    /* Hide the hamburger icon by default */
    font-size: 24px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .navbar {
        display: none;
        /* Hide the navbar for smaller screens */
    }

    .hamburger-icon {
        display: block;
        /* Show the hamburger icon on smaller screens */
    }

    .header {
        display: flex;
    }

    .container {
        padding: 0 16px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        padding: 0 16px;
    }
}