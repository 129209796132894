/* ConfirmationAlert.css */
@import url('../../styles/global.css');

.alert-title {
    font-size: 14pt;
    font-weight: 500;
    margin: 0;
}

.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 30%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.cancel-button,
.confirm-button {
    background-color: #ea4335;
    /* Material3 Red */
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 8px;
    border-radius: 4px;
    cursor: pointer;
}

.confirm-button {
    background-color: #34a853;
    /* Material3 Green */
}

.action-button-container {
    margin-top: 36px;
    display: flex;
    justify-content: flex-end;
}

/* Responsive layout for smaller screens */
@media (min-width: 768px) {
    .confirmation-content {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .confirmation-content {
        width: 60%;
    }
}

@media screen and (min-width: 1200px) {
    .confirmation-content {
        width: 40%;
        display: inline;
    }
}