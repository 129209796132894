/* footer.css */

.footer-container {
  background-color: #1E183B;
  color: #fff;
  padding: 48px 16px;
  font-size: 10pt;
  text-align: center;
  width: 100%;
}

.quick-links {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.quick-links a:hover {
  text-decoration: underline;
}

.quick-link {
  color: #fff;
  margin: 0 10px;
  text-decoration: underline;
}

.menu-items {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.menu-items a:hover{
  text-decoration: underline;
}

.menu-item {
  color: #fff;
  margin: 0 10px;
  text-decoration: underline;
}

.footer-button {
  margin-bottom: 20px;
}

.solid-button {
  background-color: #4a3b8d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.solid-button:hover {
  background-color: #342c6d;
}
